'use client';
import React, {useEffect, useState} from 'react';
import ClientOnly from './clientOnly';
import { useAccount, useNetwork } from "wagmi";
import { disconnect } from '@wagmi/core'
import HomeNotConected from './components/home_not_conected';
import Swal from "sweetalert2";
import Countdown from 'react-countdown';
import { CountdownRendererFn } from 'react-countdown';
import Link from 'next/link';
import { sendTransactionToPool } from "./web3_functions";

import {
    ButtonGroup,
    Switch,
    useMediaQuery,
    Paper,
    Tooltip,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Badge,
    Grid,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Container,
    Box,
    Button,
    Typography,
    CircularProgress,
    Menu, MenuItem
} from '@mui/material';

import { InfoOutlined, ReportGmailerrorredOutlined, ArrowDropUp, ArrowDropDown, Cloud, LiveHelp } from '@mui/icons-material';

import { sendPoolPayoutToApi, sendAnglePoolActivateToApi, sendClaimToApi } from "./api";
import {switchNetwork} from "@wagmi/core";
import './wallet.css';
import { useTheme } from '@mui/material/styles';

import PoolInfoModal from './infomodal';
import Loading from "./loading";

interface VolumeData {
    ges_volume_wrapped: string;
    ges_volume_master: string;
}



export default function Wallet() {
    const [AngelPoolActive, setAngelPoolActive] = useState(false);
    const [CountPoolActive, setCountPoolActive] = useState(0);
    const [showSteps, setShowSteps] = useState(false);
    const [poolarray, setPoolarray] = useState({});

    const [globaldata, setGlobalData] = useState({});

    const [airdropwinsarray, setAirdropWinsarray] = useState<Array<any>>([]);

    const [selectedPoolInfo, setSelectedPoolInfo] = useState<null | string>('');

    const [modalPoolInfoShow, setModalPoolInfoShow] = useState(false);

    const [loading, setLoading] = useState(false);
    const [loadingActivateAnglePool, setLoadingActivateAnglePool] = useState(false);
    const [loadingPayout, setLoadingPayout] = useState(false);
    const [ProzessPayout, setProzessPayout] = useState(false);
    const [loadingClaim1, setLoadingClaim1] = useState(false);
    const [loadingClaim2, setLoadingClaim2] = useState(false);
    const [loadingClaim3, setLoadingClaim3] = useState(false);
    const [loadingClaim4, setLoadingClaim4] = useState(false);
    const [loadingClaim5, setLoadingClaim5] = useState(false);
    const [loadingClaim6, setLoadingClaim6] = useState(false);
    const [loadingClaim7, setLoadingClaim7] = useState(false);
    const [loadingClaim8, setLoadingClaim8] = useState(false);
    const [loadingClaim9, setLoadingClaim9] = useState(false);
    const [ProzessClaim1, setProzessClaim1] = useState(false);
    const [ProzessClaim2, setProzessClaim2] = useState(false);
    const [ProzessClaim3, setProzessClaim3] = useState(false);
    const [ProzessClaim4, setProzessClaim4] = useState(false);
    const [ProzessClaim5, setProzessClaim5] = useState(false);
    const [ProzessClaim6, setProzessClaim6] = useState(false);
    const [ProzessClaim7, setProzessClaim7] = useState(false);
    const [ProzessClaim8, setProzessClaim8] = useState(false);
    const [ProzessClaim9, setProzessClaim9] = useState(false);
    const [paymentSuccessful, setPaymentSuccessful] = useState(false);
    const [spezialselected, setSpezialSelected] = useState(false);
    const [isWFLR, setIsWFLR] = useState(true);
    const [isPoolLoading, setIsPoolLoading] = useState(true);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const [gesvolumeall, setGesVolumeAll] = useState<number>(0);

    const theme = useTheme();
    const isMobile = useMediaQuery('(max-width: 600px)');

    const { chain } = useNetwork();
    const { address, isConnected } = useAccount();

    const handlePoolInfoClick = (poolId: string) => {
        setSelectedPoolInfo(poolId);
        setModalPoolInfoShow(true);
    };

    const handlePoolInfoModalClose = () => {
        setModalPoolInfoShow(false);
    };


    let walletaddress: `0x${string}` | undefined = address;

    const countdownRenderer: CountdownRendererFn = ({ days, hours, minutes }) => {
        return (
            <span>
            {days}d {hours}h {minutes}m
        </span>
        );
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                let GlobalData: VolumeData[];
                if (chain?.id === 14 || chain?.id === 16 || chain?.id === 19 || chain?.id === 114) {
                    const response = await fetch('/api/pools/get-all-volume/', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            chainid: chain?.id,
                        }),
                    });
                    GlobalData = await response.json();
                } else {
                    setGlobalData([]);
                    return;
                }

                let ges_volume_master;
                let ges_volume_wrapped;
                ges_volume_master = parseFloat(GlobalData[0]?.ges_volume_master || '0');
                ges_volume_wrapped = parseFloat(GlobalData[0]?.ges_volume_wrapped || '0');
                const ges_volume_all = ges_volume_master + ges_volume_wrapped;
                const ges_volume_all_without_decimal = ges_volume_all.toFixed(0);
                setGesVolumeAll(Number(ges_volume_all_without_decimal));
            } catch (error) {
                console.error(error);
                setGlobalData([]);
            }
        };

        fetchData().then(response => {
            console.log(response);
        });
    }, [chain?.id]);


    useEffect(() => {
        const fetchPoolData = async () => {
            setIsPoolLoading(true);
            let ActivPools;
            if (chain?.id === 14 || chain?.id === 16 || chain?.id === 19 || chain?.id === 114) {
                ActivPools = fetch('/api/pools/check-wallet/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        address: walletaddress,
                        chainid: chain?.id,
                    }),
                }).then((response) => response.json());
            } else {
                setPoolarray([]);
                return;
            }

            try {
                ActivPools.then((data) => {
                    const pools = data.pools || [];
                    const airdroprewards = data.airdroprewards || [];
                    setPoolarray(pools);
                    setAirdropWinsarray(airdroprewards);
                    let activePoolsCount = 0;
                    if (typeof pools === 'object' && pools !== null) {
                        Object.entries(pools).forEach(([, pool]: [string, any]) => {
                            if (pool?.activ === true) {
                                activePoolsCount++;
                            }
                        });
                    }
                    setCountPoolActive(activePoolsCount);

                    const spezialPoolActivValue = pools['10000']?.spezial_pool_activ;
                    const isAngelPoolActive = spezialPoolActivValue === true;
                    setAngelPoolActive(isAngelPoolActive);
                    setIsPoolLoading(false);
                }).catch((error) => {
                    console.error(error);
                    setPoolarray([]);
                    setAirdropWinsarray([]);
                    setIsPoolLoading(false);
                });
            } catch (error) {
                console.error(error);
                setPoolarray([]);
                setIsPoolLoading(false);
            }
        };

        if (paymentSuccessful) {
            setTimeout(() => {
                setPaymentSuccessful(false);
                fetchPoolData().then(response => {
                    console.log(response);
                });
            }, 5000);
        } else {
            fetchPoolData().then(response => {
                console.log(response);
            });
        }

    }, [address, walletaddress, chain?.id, CountPoolActive, AngelPoolActive, isConnected, paymentSuccessful, ProzessPayout, ProzessClaim1, ProzessClaim2, ProzessClaim3, ProzessClaim4, ProzessClaim5, ProzessClaim6, ProzessClaim7, ProzessClaim8, ProzessClaim9]);

    const AirdropRewards = ({ countpoolactive, anglepoolactive }: { countpoolactive: number, anglepoolactive: boolean }) => {
        const step1 = 'XS';
        const step2 = 'S';
        const step3 = 'M';
        const step4 = 'L';
        const step5 = 'XL';
        const step6 = 'XXL';
        const toggleSteps = () => {
            setShowSteps(!showSteps);
        };


        return (
            <>
                <Card>
                    <CardHeader
                        title={
                            <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                               <Badge badgeContent={countpoolactive} color="secondary">Active Pools</Badge>
                                <Button onClick={toggleSteps} sx={{ color: 'white', fontSize: '1.5rem' }}>
                                    {showSteps ? <ArrowDropUp /> : <ArrowDropDown />}
                                </Button>
                            </span>
                        }
                        sx={{
                            backgroundColor:  'rgb(25, 135, 84, 1)',
                            color: 'white',
                            padding: '8px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderTopLeftRadius: '8px',
                            borderTopRightRadius: '8px',
                        }}
                    />
                    <CardContent>
                        {countpoolactive === 0 &&
                            <>
                                <Typography variant="h5" fontWeight="bold">
                                    Activate a pool to enter the FTSO lottery for active pools twice a week.
                                </Typography>

                                <Typography variant="body1" mt={4}>
                                    Additionally, you can participate in the monthly drawing of <strong style={{ fontWeight: 'bold' }}>4.5%</strong> of the Flare Drop distribution from the active pool. Once you have <strong style={{ fontWeight: 'bold' }}>4</strong> active pools, you become eligible to participate in the first big POT draw <strong style={{ fontWeight: 'bold' }}>(XS = 0.5%)</strong>, the monthly payout from all 9 pools of the FTSO lottery.
                                </Typography>

                                <Typography variant="h5" fontWeight="bold" mt={2}>
                                    Become an ANGEL and be one of only 500 members!
                                </Typography>

                                <Typography variant="body1" mt={4}>
                                    As an ANGEL (in pool 10000), you have an active pool and associated permissions. Additionally, you can take part in the ANGEL POT, and you have the chance to be one of the three winners out of a maximum of 500.
                                </Typography>

                                <Typography variant="body2" mt={2}>
                                    In the monthly draw, 3% goes to the first-place winner, 1.5% goes to the second-place winner, and 0.5% goes to the third-place winner. The payout comes from all 9 pools of the FTSO lottery.
                                </Typography>
                            </>
                        }

                        {countpoolactive === 1 &&
                            (!anglepoolactive ?
                                    <>
                                        <Typography variant="h5" fontWeight="bold">
                                            Congratulations on having an active pool!
                                        </Typography>

                                        <p className="mt-4">
                                            By activating another pool, you will have the opportunity to participate in the FTSO lottery <strong className="font-bold">4</strong> times a week. Additionally, you will be eligible to participate in the monthly draws of <strong className="font-bold">4.5%</strong> of the payout from your two active pools.
                                        </p>
                                        <p className="mt-2">
                                            Once you have a total of <strong className="font-bold">4</strong> active pools, you will be able to participate in the first big POT draw <strong className="font-bold">(XS = 0.5%)</strong> which is a monthly payout from all 9 pools of the FTSO lottery.
                                        </p>

                                        <Typography variant="h5" fontWeight="bold" mt={2}>
                                            Become an ANGEL and be one of only 500 members!
                                        </Typography>

                                        <p className="mt-4">
                                            As an ANGEL (in pool 10000), you have an active pool and associated permissions. Additionally, you can take part in the ANGEL POT, and you have the chance to be one of the three winners out of a maximum of 500.
                                        </p>
                                        <p className="mt-2">
                                            In the monthly draw, 3% goes to the first-place winner, 1.5% goes to the second-place winner, and 0.5% goes to the third-place winner. The payout comes from all 9 pools of the FTSO lottery.
                                        </p>
                                    </>
                                    :
                                    <>
                                        <h2 className="text-2xl font-bold">Great job on becoming an ANGEL and securing your chance of winning until 01/30/2026! </h2>
                                        <p className="mt-4">
                                            By activating another pool, you&apos;ll have the opportunity to participate in the FTSO lottery <strong className="font-bold">4</strong> times a week. Additionally, you&apos;ll be eligible for the monthly draws, receiving <strong className="font-bold">4.5%</strong> of the payout from your <strong className="font-bold">2</strong> active pools. Once you have4 active pools, you&apos;ll also be eligible to participate in the first big POT draw <strong className="font-bold">(XS = 0.5%)</strong>, which is a monthly payout from all 9 pools of the FTSO lottery. Keep up the good work!
                                        </p>
                                    </>
                            )
                        }

                        {countpoolactive === 2 &&
                            (!anglepoolactive ?
                                    <>
                                        <p className="mt-4">
                                            <strong className="font-bold">Great job! By activating another pool,</strong> you&apos;ll have the opportunity to participate in the FTSO lottery <strong className="font-bold">6</strong> times a week. You&apos;ll also be eligible for the monthly draws, receiving <strong className="font-bold">4.5%</strong> of the payout from your <strong className="font-bold">3</strong> active pools.
                                        </p>
                                        <p className="mt-2">And once you have <strong className="font-bold">4</strong> active pools, you&apos;ll be eligible to participate in the first big POT draw <strong className="font-bold">(XS = 0.5%)</strong>, which is a monthly payout from all 9 pools of the FTSO lottery. Keep up the good work!</p>

                                        <Typography variant="h5" fontWeight="bold" mt={2}>
                                            Become an ANGEL and be one of only 500 members!
                                        </Typography>

                                        <p className="mt-4">As an ANGEL (in pool 10000), you have an active pool and associated permissions. Additionally, you can take part in the ANGEL POT, and you have the chance to be one of the three winners out of a maximum of 500.</p>
                                        <p className="mt-2">In the monthly draw, 3% goes to the first-place winner, 1.5% goes to the second-place winner, and 0.5% goes to the third-place winner. The payout comes from all 9 pools of the FTSO lottery.</p>

                                    </>
                                    :
                                    <>
                                        <p className="mt-4">
                                            <strong>Great job on becoming an ANGEL and securing your chance of winning until 01/30/2026! By activating another pool</strong>, you&apos;ll have the opportunity to participate in the FTSO lottery a total of <strong className="font-bold">6</strong> times a week. Additionally, you&apos;ll be eligible for the monthly draws, receiving <strong className="font-bold">4.5%</strong> of the payout from your <strong className="font-bold">3</strong> active pools.
                                        </p>
                                        <p className="mt-2">
                                            Once you have <strong className="font-bold">4</strong> active pools, you&apos;ll also be eligible to participate in the first big POT draw <strong className="font-bold">(XS = 0.5%)</strong>, which is a monthly payout from all 9 pools of the FTSO lottery. Keep up the good work and good luck in your future winnings!
                                        </p>
                                    </>
                            )
                        }

                        {countpoolactive === 3 &&
                            (!anglepoolactive ?
                                    <>
                                        <p className="mt-4">
                                            <strong className="font-bold">That&apos;s correct! Once you have 4 active pools, you&apos;ll be eligible to participate in the XS POT draw</strong>, which is a monthly payout <strong className="font-bold">0,5%</strong> from all 9 pools of the FTSO lottery. Activating another pool will also allow you to participate in the FTSO lottery a total of 8 times a week, and you&apos;ll receive <strong className="font-bold">4.5%</strong> of the payout from your <strong className="font-bold">4</strong> active pools in the monthly draws. Keep up the good work!
                                        </p>

                                        <Typography variant="h5" fontWeight="bold" mt={2}>
                                            Become an ANGEL and be one of only 500 members!
                                        </Typography>

                                        <p className="mt-4">As an ANGEL (in pool 10000), you have an active pool and associated permissions. Additionally, you can take part in the ANGEL POT, and you have the chance to be one of the three winners out of a maximum of 500.</p>
                                        <p className="mt-2">In the monthly draw, 3% goes to the first-place winner, 1.5% goes to the second-place winner, and 0.5% goes to the third-place winner. The payout comes from all 9 pools of the FTSO lottery.</p>

                                    </>
                                    :
                                    <>
                                        <p className="mt-4">
                                            <strong className="font-bold">That&apos;s correct! Once you have 4 active pools, you&apos;ll be eligible to participate in the XS POT draw</strong>, which is a monthly payout of <strong className="font-bold">0,5%</strong> from all 9 pools of the FTSO lottery. Activating another pool will also allow you to participate in the FTSO lottery a total of <strong className="font-bold">8</strong> times a week, and you&apos;ll receive <strong className="font-bold">4.5%</strong> of the payout from your <strong className="font-bold">4</strong> active pools in the monthly draws. Keep up the good work!
                                        </p>
                                    </>
                            )
                        }

                        {countpoolactive === 4 &&
                            (!anglepoolactive ?
                                    <>
                                        <p className="mt-4">
                                            <strong className="font-bold">That&apos;s right! Once you have 5 active pools, you&apos;ll be eligible to participate in the S POT draw</strong>, which is a monthly payout of <strong className="font-bold">1%</strong> from all 9 pools of the FTSO lottery. Activating another pool will also allow you to participate in the FTSO lottery a total of <strong className="font-bold">10</strong> times a week, and you&apos;ll receive <strong className="font-bold">4.5%</strong> of the payout from your 5 active pools in the monthly draws. Keep up the good work!
                                        </p>

                                        <Typography variant="h5" fontWeight="bold" mt={2}>
                                            Become an ANGEL and be one of only 500 members!
                                        </Typography>

                                        <p className="mt-4">As an ANGEL (in pool 10000), you have an active pool and associated permissions. Additionally, you can take part in the ANGEL POT, and you have the chance to be one of the three winners out of a maximum of 500.</p>
                                        <p className="mt-2">In the monthly draw, 3% goes to the first-place winner, 1.5% goes to the second-place winner, and 0.5% goes to the third-place winner. The payout comes from all 9 pools of the FTSO lottery.</p>

                                    </>
                                    :
                                    <>
                                        <p className="mt-4">
                                            <strong className="font-bold">That&apos;s right! Once you have 5 active pools, you&apos;ll be eligible to participate in the S POT draw</strong>, which is a monthly payout of <strong className="font-bold">1%</strong> from all 9 pools of the FTSO lottery. Activating another pool will also allow you to participate in the FTSO lottery a total of <strong className="font-bold">10</strong> times a week, and you&apos;ll receive <strong className="font-bold">4.5%</strong> of the payout from your <strong className="font-bold">5</strong> active pools in the monthly draws. Keep up the good work!
                                        </p>
                                    </>
                            )
                        }

                        {countpoolactive === 5 &&
                            (!anglepoolactive ?
                                    <>
                                        <p className="mt-4">
                                            <strong className="font-bold">That&apos;s correct! Once you have 6 active pools, you&apos;ll be eligible to participate in the M  POT draw,</strong> which is a monthly payout of <strong className="font-bold">1,5%</strong>  from all 9 pools of the FTSO lottery. Activating another pool will also allow you to participate in the FTSO lottery a total of <strong className="font-bold">12</strong> times a week, and you&apos;ll receive <strong className="font-bold">4.5%</strong> of the payout from your <strong className="font-bold">6</strong> active pools in the monthly draws. Good luck!
                                        </p>

                                        <Typography variant="h5" fontWeight="bold" mt={2}>
                                            Become an ANGEL and be one of only 500 members!
                                        </Typography>

                                        <p className="mt-4">As an ANGEL (in pool 10000), you have an active pool and associated permissions. Additionally, you can take part in the ANGEL POT, and you have the chance to be one of the three winners out of a maximum of 500.</p>
                                        <p className="mt-2">In the monthly draw, 3% goes to the first-place winner, 1.5% goes to the second-place winner, and 0.5% goes to the third-place winner. The payout comes from all 9 pools of the FTSO lottery.</p>

                                    </>
                                    :
                                    <>
                                        <p className="mt-4">
                                            <strong className="font-bold">That&apos;s correct! Once you have 6 active pools, you&apos;ll be eligible to participate in the M  POT draw</strong>, which is a monthly payout of <strong className="font-bold">1,5%</strong>  from all 9 pools of the FTSO lottery. Activating another pool will also allow you to participate in the FTSO lottery a total of <strong className="font-bold">12</strong> times a week, and you&apos;ll receive <strong className="font-bold">4.5%</strong> of the payout from your 6 active pools in the monthly draws. Good luck!
                                        </p>
                                    </>
                            )
                        }

                        {countpoolactive === 6 &&
                            (!anglepoolactive ?
                                    <>
                                        <p className="mt-4">
                                            <strong className="font-bold">That&apos;s correct! Once you activate your 7th pool, you&apos;ll be eligible to participate in the L POT draw</strong>, which is a monthly payout of <strong className="font-bold">2%</strong>  from all 9 pools of the FTSO lottery. You&apos;ll also have the opportunity to participate in the FTSO lottery a total of <strong className="font-bold">14</strong> times a week and receive <strong className="font-bold">4.5%</strong> of the payout from your <strong className="font-bold">7</strong> active pools in the monthly draws. Keep up the good work!
                                        </p>

                                        <Typography variant="h5" fontWeight="bold" mt={2}>
                                            Become an ANGEL and be one of only 500 members!
                                        </Typography>

                                        <p className="mt-4">As an ANGEL (in pool 10000), you have an active pool and associated permissions. Additionally, you can take part in the ANGEL POT, and you have the chance to be one of the three winners out of a maximum of 500.</p>
                                        <p className="mt-2">In the monthly draw, 3% goes to the first-place winner, 1.5% goes to the second-place winner, and 0.5% goes to the third-place winner. The payout comes from all 9 pools of the FTSO lottery.</p>

                                    </>
                                    :
                                    <>
                                        <p className="mt-4">
                                            <strong className="font-bold">That&apos;s correct! Once you activate your 7th pool, you&apos;ll be eligible to participate in the L POT draw</strong>, which is a monthly payout of <strong className="font-bold">2%</strong>  from all 9 pools of the FTSO lottery. You&apos;ll also have the opportunity to participate in the FTSO lottery a total of <strong className="font-bold">14</strong> times a week and receive <strong className="font-bold">4.5%</strong> of the payout from your <strong className="font-bold">7</strong> active pools in the monthly draws. Keep up the good work!
                                        </p>
                                    </>
                            )
                        }

                        {countpoolactive === 7 &&
                            (!anglepoolactive ?
                                    <>
                                        <Typography variant="body1" mt={2}>
                                            <strong style={{ fontWeight: 'bold' }}>That&apos;s correct! Once you have 8 active pools, you&apos;ll be eligible to participate in the XL POT draw</strong>, which is a monthly payout of <strong style={{ fontWeight: 'bold' }}>2,5%</strong> from all 9 pools of the FTSO lottery. Activating another pool will also allow you to participate in the FTSO lottery a total of <strong style={{ fontWeight: 'bold' }}>16</strong> times a week, and you&apos;ll receive <strong style={{ fontWeight: 'bold' }}>4.5%</strong> of the payout from your <strong style={{ fontWeight: 'bold' }}>8</strong> active pools in the monthly draws. Keep up the good work!
                                        </Typography>

                                        <Typography variant="h5" fontWeight="bold" mt={2}>
                                            Become an ANGEL and be one of only 500 members!
                                        </Typography>

                                        <p className="mt-4">As an ANGEL (in pool 10000), you have an active pool and associated permissions. Additionally, you can take part in the ANGEL POT, and you have the chance to be one of the three winners out of a maximum of 500.</p>
                                        <p className="mt-2">In the monthly draw, 3% goes to the first-place winner, 1.5% goes to the second-place winner, and 0.5% goes to the third-place winner. The payout comes from all 9 pools of the FTSO lottery.</p>

                                    </>
                                    :
                                    <>
                                        <p className="mt-4">
                                            <strong className="font-bold">That&apos;s correct! Once you have 8 active pools, you&apos;ll be eligible to participate in the XL POT draw</strong>, which is a monthly payout of <strong className="font-bold">2,5%</strong> from all 9 pools of the FTSO lottery. Activating another pool will also allow you to participate in the FTSO lottery a total of <strong className="font-bold">16</strong> times a week, and you&apos;ll receive <strong className="font-bold">4.5%</strong> of the payout from your <strong className="font-bold">8</strong> active pools in the monthly draws. Keep up the good work!
                                        </p>
                                    </>
                            )
                        }

                        {countpoolactive === 8 &&
                            (!anglepoolactive ?
                                    <>
                                        <Typography variant="body1" mt={2}>
                                            <strong style={{ fontWeight: 'bold' }}>That&apos;s correct! Once you have 9 active pools, you&apos;ll be eligible to participate in the XXL POT draw</strong>, which is a monthly payout of <strong style={{ fontWeight: 'bold' }}>3%</strong> from all 9 pools of the FTSO lottery. Activating another pool will also allow you to participate in the FTSO lottery a total of <strong style={{ fontWeight: 'bold' }}>18</strong> times a week, and you&apos;ll receive <strong style={{ fontWeight: 'bold' }}>4.5%</strong> of the payout from your <strong style={{ fontWeight: 'bold' }}>9</strong> active pools in the monthly draws. Keep up the good work!
                                        </Typography>

                                        <Typography variant="h5" fontWeight="bold" mt={2}>
                                            Become an ANGEL and be one of only 500 members!
                                        </Typography>

                                        <Typography variant="body1" mt={2}>
                                            As an ANGEL (in pool 10000), you have an active pool and associated permissions. Additionally, you can take part in the ANGEL POT, and you have the chance to be one of the three winners out of a maximum of 500.
                                        </Typography>
                                        <Typography variant="body1" mt={6}>
                                            In the monthly draw, 3% goes to the first-place winner, 1.5% goes to the second-place winner, and 0.5% goes to the third-place winner. The payout comes from all 9 pools of the FTSO lottery.
                                        </Typography>
                                    </>
                                    :
                                    <>
                                        <Typography variant="body1" mt={4}>
                                            <strong style={{ fontWeight: 'bold' }}>That&apos;s correct! Once you have 9 active pools, you&apos;ll be eligible to participate in the XXL POT draw</strong>, which is a monthly payout of <strong style={{ fontWeight: 'bold' }}>3%</strong> from all 9 pools of the FTSO lottery. Activating another pool will also allow you to participate in the FTSO lottery a total of <strong style={{ fontWeight: 'bold' }}>18</strong> times a week, and you&apos;ll receive <strong style={{ fontWeight: 'bold' }}>4.5%</strong> of the payout from your <strong style={{ fontWeight: 'bold' }}>9</strong> active pools in the monthly draws. Keep up the good work!
                                        </Typography>
                                    </>
                            )
                        }

                        {countpoolactive === 9&&
                            (!anglepoolactive ?
                                    <>
                                        <Typography variant="h5" fontWeight="bold">
                                            Congratulations, you have activated all 9 pools, which is a great achievement!
                                        </Typography>
                                        <Typography variant="body1" mt={4}>
                                            If you have had a great experience with us, we kindly ask you to recommend us to your friends and family. Additionally, please follow our social media accounts to stay up to date with the latest news and promotions.
                                        </Typography>

                                        <Typography variant="body1" mt={2}>
                                            Remember, the more players we have, the bigger the winnings will be for everyone. So, spread the word and let&apos;s all win together!
                                        </Typography>

                                        <Typography variant="body1" mt={2}>
                                            <strong style={{ fontWeight: 'bold' }}>By activating your ANGEL pool, you increase your chances of winning the FTSO lottery to the maximum possible level.</strong>
                                        </Typography>

                                        <Typography variant="h6" fontWeight="bold" mt={6}>
                                            Become an ANGEL and be one of only 500 members!
                                        </Typography>
                                        <Typography variant="body1" mt={4}>
                                            As an ANGEL (in pool 10000), you have an active pool and associated permissions. Additionally, you can take part in the ANGEL POT, and you have the chance to be one of the three winners out of a maximum of 500.
                                        </Typography>
                                        <Typography variant="body1" mt={2}>
                                            In the monthly draw, 3% goes to the first-place winner, 1.5% goes to the second-place winner, and 0.5% goes to the third-place winner. The payout comes from all 9 pools of the FTSO lottery.
                                        </Typography>
                                    </>
                                    :
                                    <>
                                        <Typography variant="h5" fontWeight="bold">
                                            Congratulations you are an ANGEL and have all 9 pools active!
                                        </Typography>
                                        <Typography variant="body1" mt={4}>
                                            Great job, you are an ANGEL with all 9 pools active! We wish you the best of luck and hope you win big.
                                        </Typography>
                                        <Typography variant="body1" mt={2}>
                                            If you have had a great experience with us, we kindly ask you to recommend us to your friends and family. Additionally, please follow our social media accounts to stay up to date with the latest news and promotions.
                                        </Typography>
                                        <Typography variant="body1" mt={2}>
                                            Remember, the more players we have, the bigger the winnings will be for everyone. So, spread the word and let&apos;s all win together!
                                        </Typography>
                                    </>
                            )
                        }


                        <TableContainer component={Paper} sx={{ display: showSteps || countpoolactive >= 4 ? 'block' : 'none', padding: '0', marginTop: '10px', }}>
                            <Table sx={{ minWidth: 300, border: '1px solid #ddd' }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell><strong>Pot</strong></TableCell>
                                        <TableCell><strong>Qual.</strong></TableCell>
                                        <TableCell><strong>Status</strong></TableCell>
                                        <TableCell />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow sx={{ display: showSteps || countpoolactive >= 4 ? 'table-row' : 'none', borderBottom: '2px solid #ddd', backgroundColor: countpoolactive >= 4 ? '#d1e7dd' : '#fff3cd'}}>
                                        <TableCell sx={{ fontWeight: 'bold', padding: '0 0 0 2px', textAlign: isMobile ? 'center' : 'left', }}>
                                            <Tooltip title="Unlock the chance to win 0.5% of the total FlareDrop of the FTSOLottery by having 4 or more active pools." arrow
                                                     sx={{
                                                         display: isMobile ? 'none' : 'inline-block', // Hier wird das Tooltip auf Mobilgeräten ausgeblendet
                                                     }}
                                            >
                                                <InfoOutlined sx={{ height: '1.25rem', width: '1.25rem', color: '#2e7239' }} />
                                            </Tooltip>
                                            &nbsp;{step1}
                                        </TableCell>
                                        <TableCell sx={{ fontWeight: 'bold', textAlign: isMobile ? 'center' : 'left', }}>{airdropwinsarray[3]?.walletCount}</TableCell>
                                        <TableCell>

                                            {countpoolactive >= 4 ?
                                                <Typography
                                                    variant="body2"
                                                    component="span"
                                                    sx={{
                                                        backgroundColor: 'rgb(25, 135, 84, 1)',
                                                        color: 'white',
                                                        ml: 'auto',
                                                        p: 0.5,
                                                        borderRadius: '4px',
                                                    }}
                                                >
                                                    Active
                                                </Typography>
                                                :

                                                <Typography
                                                    variant="body2"
                                                    component="span"
                                                    sx={{
                                                        backgroundColor: 'rgb(220, 53, 69, 1)',
                                                        color: 'white',
                                                        ml: 'auto',
                                                        p: 0.5,
                                                        borderRadius: '4px',
                                                    }}
                                                >
                                                    Inactive
                                                </Typography>

                                            }

                                        </TableCell>
                                        <TableCell sx={{ textAlign: 'right' }}>
                                            {countpoolactive >= 4 && airdropwinsarray[3]?.volume > 0 ? (
                                                airdropwinsarray[3]?.is_clam_requested === 0 ? (
                                                    <Button
                                                        variant="outlined"
                                                        sx={{
                                                            color: theme => theme.palette.success.main,
                                                            borderColor: theme => theme.palette.success.main,
                                                            '&:hover': {
                                                                backgroundColor: theme => theme.palette.success.dark,
                                                                color: theme => theme.palette.common.white,
                                                            },
                                                        }}
                                                        size="small"
                                                        onClick={() => sendAirdropClaim(chain?.id, walletaddress, 3)}
                                                        disabled={loadingClaim3}
                                                    >
                                                        {loadingClaim3 ?
                                                            <>Claim im Prozess <CircularProgress size={13} sx={{ color: 'gray', marginLeft: 1 }}  /></>
                                                            :
                                                            <>Claim {`${Number(airdropwinsarray[3]?.volume).toFixed(3)} ${chain?.id === 14 ? 'WFLR' : 'WC2FLR'}`}</>
                                                        }
                                                    </Button>
                                                ) : (
                                                    airdropwinsarray[3]?.is_clam_requested_status === 1 ? (
                                                        <Button
                                                            variant="outlined"
                                                            sx={{
                                                                color: theme => theme.palette.success.main,
                                                                borderColor: theme => theme.palette.success.main,
                                                                '&:hover': {
                                                                    backgroundColor: theme => theme.palette.success.dark,
                                                                    color: theme => theme.palette.common.white,
                                                                },
                                                            }}
                                                            size="small"
                                                            disabled
                                                        >
                                                            Payout Prozess..  <CircularProgress size={13} sx={{ color: 'gray', marginLeft: 1 }}  />
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            variant="outlined"
                                                            sx={{
                                                                color: theme => theme.palette.success.main,
                                                                borderColor: theme => theme.palette.success.main,
                                                                '&:hover': {
                                                                    backgroundColor: theme => theme.palette.success.dark,
                                                                    color: theme => theme.palette.common.white,
                                                                },
                                                            }}
                                                            size="small"
                                                            disabled
                                                        >
                                                            Check Prozess.. <CircularProgress size={13} sx={{ color: 'gray', marginLeft: 1 }}  />
                                                        </Button>
                                                    )
                                                )
                                            ) : (
                                                countpoolactive >= 4 &&
                                                <>
                                                    <Button
                                                        variant="outlined"
                                                        sx={{
                                                            color: 'red',
                                                            borderColor: 'red',
                                                            '&:hover': {
                                                                backgroundColor: 'red',
                                                                color: 'white',
                                                            },
                                                        }}
                                                        size="small"
                                                        disabled
                                                    >
                                                        No wins found
                                                    </Button>
                                                </>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ display: showSteps || countpoolactive >= 5 ? 'table-row' : 'none', borderBottom: '2px solid #ddd', backgroundColor: countpoolactive >= 5 ? '#d1e7dd' : '#fff3cd'}}>
                                        <TableCell sx={{ fontWeight: 'bold', padding: '0 0 0 2px', textAlign: isMobile ? 'center' : 'left', }}>
                                            <Tooltip title="Unlock the chance to win 1% of the total FlareDrop of the FTSOLottery by having 5 or more active pools.." arrow
                                                     sx={{
                                                         display: isMobile ? 'none' : 'inline-block', // Hier wird das Tooltip auf Mobilgeräten ausgeblendet
                                                     }}
                                            >
                                                <InfoOutlined sx={{ height: '1.25rem', width: '1.25rem', color: '#2e7239' }} />
                                            </Tooltip>
                                            &nbsp;{step2}

                                        </TableCell>
                                        <TableCell sx={{ fontWeight: 'bold', textAlign: isMobile ? 'center' : 'left', }}>{airdropwinsarray[4]?.walletCount}</TableCell>
                                        <TableCell>

                                            {countpoolactive >= 5 ?
                                                <Typography
                                                    variant="body2"
                                                    component="span"
                                                    sx={{
                                                        backgroundColor: 'rgb(25, 135, 84, 1)',
                                                        color: 'white',
                                                        ml: 'auto',
                                                        p: 0.5,
                                                        borderRadius: '4px',
                                                    }}
                                                >
                                                    Active
                                                </Typography>
                                                :

                                                <Typography
                                                    variant="body2"
                                                    component="span"
                                                    sx={{
                                                        backgroundColor: 'rgb(220, 53, 69, 1)',
                                                        color: 'white',
                                                        ml: 'auto',
                                                        p: 0.5,
                                                        borderRadius: '4px',
                                                    }}
                                                >
                                                    Inactive
                                                </Typography>

                                            }

                                        </TableCell>
                                        <TableCell sx={{ textAlign: 'right' }}>
                                            {countpoolactive >= 5 && airdropwinsarray[4]?.volume > 0 ? (
                                                airdropwinsarray[4]?.is_clam_requested === 0 ? (
                                                    <Button
                                                        variant="outlined"
                                                        sx={{
                                                            color: theme => theme.palette.success.main,
                                                            borderColor: theme => theme.palette.success.main,
                                                            '&:hover': {
                                                                backgroundColor: theme => theme.palette.success.dark,
                                                                color: theme => theme.palette.common.white,
                                                            },
                                                        }}
                                                        size="small"
                                                        onClick={() => sendAirdropClaim(chain?.id, walletaddress, 4)}
                                                        disabled={loadingClaim4}
                                                    >
                                                        {loadingClaim4 ?
                                                            <>Claim im Prozess <CircularProgress size={13} sx={{ color: 'gray', marginLeft: 1 }}  /></>
                                                            :
                                                            <>Claim {`${Number(airdropwinsarray[4]?.volume).toFixed(3)} ${chain?.id === 14 ? 'WFLR' : 'WC2FLR'}`}</>
                                                        }
                                                    </Button>
                                                ) : (
                                                    airdropwinsarray[4]?.is_clam_requested_status === 1 ? (
                                                        <Button
                                                            variant="outlined"
                                                            sx={{
                                                                color: theme => theme.palette.success.main,
                                                                borderColor: theme => theme.palette.success.main,
                                                                '&:hover': {
                                                                    backgroundColor: theme => theme.palette.success.dark,
                                                                    color: theme => theme.palette.common.white,
                                                                },
                                                            }}
                                                            size="small"
                                                            disabled
                                                        >
                                                            Payout Prozess..  <CircularProgress size={13} sx={{ color: 'gray', marginLeft: 1 }}  />
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            variant="outlined"
                                                            sx={{
                                                                color: theme => theme.palette.success.main,
                                                                borderColor: theme => theme.palette.success.main,
                                                                '&:hover': {
                                                                    backgroundColor: theme => theme.palette.success.dark,
                                                                    color: theme => theme.palette.common.white,
                                                                },
                                                            }}
                                                            size="small"
                                                            disabled
                                                        >
                                                            Check Prozess.. <CircularProgress size={13} sx={{ color: 'gray', marginLeft: 1 }}  />
                                                        </Button>
                                                    )
                                                )
                                            ) : (
                                                countpoolactive >= 5 &&
                                                <>
                                                    <Button
                                                        variant="outlined"
                                                        sx={{
                                                            color: 'red',
                                                            borderColor: 'red',
                                                            '&:hover': {
                                                                backgroundColor: 'red',
                                                                color: 'white',
                                                            },
                                                        }}
                                                        size="small"
                                                        disabled
                                                    >
                                                        No wins found
                                                    </Button>
                                                </>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ display: showSteps || countpoolactive >= 6 ? 'table-row' : 'none', borderBottom: '2px solid #ddd', backgroundColor: countpoolactive >= 6 ? '#d1e7dd' : '#fff3cd'}}>
                                        <TableCell sx={{ fontWeight: 'bold', padding: '0 0 0 2px', textAlign: isMobile ? 'center' : 'left', }}>
                                            <Tooltip title="Unlock the chance to win 1.5% of the total FlareDrop of the FTSOLottery by having 6 or more active pools." arrow
                                                     sx={{
                                                         display: isMobile ? 'none' : 'inline-block', // Hier wird das Tooltip auf Mobilgeräten ausgeblendet
                                                     }}
                                            >
                                                <InfoOutlined sx={{ height: '1.25rem', width: '1.25rem', color: '#2e7239' }} />
                                            </Tooltip>
                                            &nbsp;{step3}
                                        </TableCell>
                                        <TableCell sx={{ fontWeight: 'bold', textAlign: isMobile ? 'center' : 'left', }}>{airdropwinsarray[5]?.walletCount}</TableCell>
                                        <TableCell>

                                            {countpoolactive >= 6 ?
                                                <Typography
                                                    variant="body2"
                                                    component="span"
                                                    sx={{
                                                        backgroundColor: 'rgb(25, 135, 84, 1)',
                                                        color: 'white',
                                                        ml: 'auto',
                                                        p: 0.5,
                                                        borderRadius: '4px',
                                                    }}
                                                >
                                                    Active
                                                </Typography>
                                                :

                                                <Typography
                                                    variant="body2"
                                                    component="span"
                                                    sx={{
                                                        backgroundColor: 'rgb(220, 53, 69, 1)',
                                                        color: 'white',
                                                        ml: 'auto',
                                                        p: 0.5,
                                                        borderRadius: '4px',
                                                    }}
                                                >
                                                    Inactive
                                                </Typography>

                                            }

                                        </TableCell>
                                        <TableCell sx={{ textAlign: 'right' }}>
                                            {countpoolactive >= 6 && airdropwinsarray[5]?.volume > 0 ? (
                                                airdropwinsarray[5]?.is_clam_requested === 0 ? (
                                                    <Button
                                                        variant="outlined"
                                                        sx={{
                                                            color: theme => theme.palette.success.main,
                                                            borderColor: theme => theme.palette.success.main,
                                                            '&:hover': {
                                                                backgroundColor: theme => theme.palette.success.dark,
                                                                color: theme => theme.palette.common.white,
                                                            },
                                                        }}
                                                        size="small"
                                                        onClick={() => sendAirdropClaim(chain?.id, walletaddress, 5)}
                                                        disabled={loadingClaim5}
                                                    >
                                                        {loadingClaim5 ?
                                                            <>Claim im Prozess <CircularProgress size={13} sx={{ color: 'gray', marginLeft: 1 }}  /></>
                                                            :
                                                            <>Claim {`${Number(airdropwinsarray[5]?.volume).toFixed(3)} ${chain?.id === 14 ? 'WFLR' : 'WC2FLR'}`}</>
                                                        }
                                                    </Button>
                                                ) : (
                                                    airdropwinsarray[5]?.is_clam_requested_status === 1 ? (
                                                        <Button
                                                            variant="outlined"
                                                            sx={{
                                                                color: theme => theme.palette.success.main,
                                                                borderColor: theme => theme.palette.success.main,
                                                                '&:hover': {
                                                                    backgroundColor: theme => theme.palette.success.dark,
                                                                    color: theme => theme.palette.common.white,
                                                                },
                                                            }}
                                                            size="small"
                                                            disabled
                                                        >
                                                            Payout Prozess..  <CircularProgress size={13} sx={{ color: 'gray', marginLeft: 1 }}  />
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            variant="outlined"
                                                            sx={{
                                                                color: theme => theme.palette.success.main,
                                                                borderColor: theme => theme.palette.success.main,
                                                                '&:hover': {
                                                                    backgroundColor: theme => theme.palette.success.dark,
                                                                    color: theme => theme.palette.common.white,
                                                                },
                                                            }}
                                                            size="small"
                                                            disabled
                                                        >
                                                            Check Prozess.. <CircularProgress size={13} sx={{ color: 'gray', marginLeft: 1 }}  />
                                                        </Button>
                                                    )
                                                )
                                            ) : (
                                                countpoolactive >= 6 &&
                                                <>
                                                    <Button
                                                        variant="outlined"
                                                        sx={{
                                                            color: 'red',
                                                            borderColor: 'red',
                                                            '&:hover': {
                                                                backgroundColor: 'red',
                                                                color: 'white',
                                                            },
                                                        }}
                                                        size="small"
                                                        disabled
                                                    >
                                                        No wins found
                                                    </Button>
                                                </>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ display: showSteps || countpoolactive >= 7 ? 'table-row' : 'none', borderBottom: '2px solid #ddd', backgroundColor: countpoolactive >= 7 ? '#d1e7dd' : '#fff3cd'}}>
                                        <TableCell sx={{ fontWeight: 'bold', padding: 0, textAlign: isMobile ? 'center' : 'left', }}>
                                            <Tooltip title="Unlock the chance to win 2% of the total FlareDrop of the FTSOLottery by having 7 or more active pools." arrow
                                                     sx={{
                                                         display: isMobile ? 'none' : 'inline-block', // Hier wird das Tooltip auf Mobilgeräten ausgeblendet
                                                     }}
                                            >
                                                <InfoOutlined sx={{ height: '1.25rem', width: '1.25rem', color: '#2e7239' }} />
                                            </Tooltip>
                                            &nbsp;{step4}
                                        </TableCell>
                                        <TableCell sx={{ fontWeight: 'bold', textAlign: isMobile ? 'center' : 'left', }}>{airdropwinsarray[6]?.walletCount}</TableCell>
                                        <TableCell>

                                            {countpoolactive >= 7 ?
                                                <Typography
                                                    variant="body2"
                                                    component="span"
                                                    sx={{
                                                        backgroundColor: 'rgb(25, 135, 84, 1)',
                                                        color: 'white',
                                                        ml: 'auto',
                                                        p: 0.5,
                                                        borderRadius: '4px',
                                                    }}
                                                >
                                                    Active
                                                </Typography>
                                                :

                                                <Typography
                                                    variant="body2"
                                                    component="span"
                                                    sx={{
                                                        backgroundColor: 'rgb(220, 53, 69, 1)',
                                                        color: 'white',
                                                        ml: 'auto',
                                                        p: 0.5,
                                                        borderRadius: '4px',
                                                    }}
                                                >
                                                    Inactive
                                                </Typography>

                                            }

                                        </TableCell>
                                        <TableCell sx={{ textAlign: 'right' }}>
                                            {countpoolactive >= 7 && airdropwinsarray[6]?.volume > 0 ? (
                                                airdropwinsarray[6]?.is_clam_requested === 0 ? (
                                                    <Button
                                                        variant="outlined"
                                                        sx={{
                                                            color: theme => theme.palette.success.main,
                                                            borderColor: theme => theme.palette.success.main,
                                                            '&:hover': {
                                                                backgroundColor: theme => theme.palette.success.dark,
                                                                color: theme => theme.palette.common.white,
                                                            },
                                                        }}
                                                        size="small"
                                                        onClick={() => sendAirdropClaim(chain?.id, walletaddress, 6)}
                                                        disabled={loadingClaim6}
                                                    >
                                                        {loadingClaim6 ?
                                                            <>Claim im Prozess <CircularProgress size={13} sx={{ color: 'gray', marginLeft: 1 }}  /></>
                                                            :
                                                            <>Claim {`${Number(airdropwinsarray[6]?.volume).toFixed(3)} ${chain?.id === 14 ? 'WFLR' : 'WC2FLR'}`}</>
                                                        }
                                                    </Button>
                                                ) : (
                                                    airdropwinsarray[6]?.is_clam_requested_status === 1 ? (
                                                        <Button
                                                            variant="outlined"
                                                            sx={{
                                                                color: theme => theme.palette.success.main,
                                                                borderColor: theme => theme.palette.success.main,
                                                                '&:hover': {
                                                                    backgroundColor: theme => theme.palette.success.dark,
                                                                    color: theme => theme.palette.common.white,
                                                                },
                                                            }}
                                                            size="small"
                                                            disabled
                                                        >
                                                            Payout Prozess..  <CircularProgress size={13} sx={{ color: 'gray', marginLeft: 1 }}  />
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            variant="outlined"
                                                            sx={{
                                                                color: theme => theme.palette.success.main,
                                                                borderColor: theme => theme.palette.success.main,
                                                                '&:hover': {
                                                                    backgroundColor: theme => theme.palette.success.dark,
                                                                    color: theme => theme.palette.common.white,
                                                                },
                                                            }}
                                                            size="small"
                                                            disabled
                                                        >
                                                            Check Prozess.. <CircularProgress size={13} sx={{ color: 'gray', marginLeft: 1 }}  />
                                                        </Button>
                                                    )
                                                )
                                            ) : (
                                                countpoolactive >= 7 &&
                                                <>
                                                    <Button
                                                        variant="outlined"
                                                        sx={{
                                                            color: 'red',
                                                            borderColor: 'red',
                                                            '&:hover': {
                                                                backgroundColor: 'red',
                                                                color: 'white',
                                                            },
                                                        }}
                                                        size="small"
                                                        disabled
                                                    >
                                                        No wins found
                                                    </Button>
                                                </>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ display: showSteps || countpoolactive >= 8 ? 'table-row' : 'none', borderBottom: '2px solid #ddd', backgroundColor: countpoolactive >= 8 ? '#d1e7dd' : '#fff3cd'}}>
                                        <TableCell sx={{ fontWeight: 'bold', padding: '0 0 0 2px', textAlign: isMobile ? 'center' : 'left', }}>
                                            <Tooltip title="Unlock the chance to win 2.5% of the total FlareDrop of the FTSOLottery by having 8 or more active pools." arrow
                                                     sx={{
                                                         display: isMobile ? 'none' : 'inline-block', // Hier wird das Tooltip auf Mobilgeräten ausgeblendet
                                                     }}
                                            >
                                                <InfoOutlined sx={{ height: '1.25rem', width: '1.25rem', color: '#2e7239' }} />
                                            </Tooltip>
                                            &nbsp;{step5}
                                        </TableCell>
                                        <TableCell sx={{ fontWeight: 'bold', textAlign: isMobile ? 'center' : 'left', }}>{airdropwinsarray[7]?.walletCount}</TableCell>
                                        <TableCell>

                                            {countpoolactive >= 8 ?
                                                <Typography
                                                    variant="body2"
                                                    component="span"
                                                    sx={{
                                                        backgroundColor: 'rgb(25, 135, 84, 1)',
                                                        color: 'white',
                                                        ml: 'auto',
                                                        p: 0.5,
                                                        borderRadius: '4px',
                                                    }}
                                                >
                                                    Active
                                                </Typography>
                                                :

                                                <Typography
                                                    variant="body2"
                                                    component="span"
                                                    sx={{
                                                        backgroundColor: 'rgb(220, 53, 69, 1)',
                                                        color: 'white',
                                                        ml: 'auto',
                                                        p: 0.5,
                                                        borderRadius: '4px',
                                                    }}
                                                >
                                                    Inactive
                                                </Typography>

                                            }

                                        </TableCell>
                                        <TableCell sx={{ textAlign: 'right' }}>
                                            {countpoolactive >= 8 && airdropwinsarray[7]?.volume > 0 ? (
                                                airdropwinsarray[7]?.is_clam_requested === 0 ? (
                                                    <Button
                                                        variant="outlined"
                                                        sx={{
                                                            color: theme => theme.palette.success.main,
                                                            borderColor: theme => theme.palette.success.main,
                                                            '&:hover': {
                                                                backgroundColor: theme => theme.palette.success.dark,
                                                                color: theme => theme.palette.common.white,
                                                            },
                                                        }}
                                                        size="small"
                                                        onClick={() => sendAirdropClaim(chain?.id, walletaddress, 7)}
                                                        disabled={loadingClaim7}
                                                    >
                                                        {loadingClaim7 ?
                                                            <>Claim im Prozess <CircularProgress size={13} sx={{ color: 'gray', marginLeft: 1 }}  /></>
                                                            :
                                                            <>Claim {`${Number(airdropwinsarray[7]?.volume).toFixed(3)} ${chain?.id === 14 ? 'WFLR' : 'WC2FLR'}`}</>
                                                        }
                                                    </Button>
                                                ) : (
                                                    airdropwinsarray[7]?.is_clam_requested_status === 1 ? (
                                                        <Button
                                                            variant="outlined"
                                                            sx={{
                                                                color: theme => theme.palette.success.main,
                                                                borderColor: theme => theme.palette.success.main,
                                                                '&:hover': {
                                                                    backgroundColor: theme => theme.palette.success.dark,
                                                                    color: theme => theme.palette.common.white,
                                                                },
                                                            }}
                                                            size="small"
                                                            disabled
                                                        >
                                                            Payout Prozess..  <CircularProgress size={13} sx={{ color: 'gray', marginLeft: 1 }}  />
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            variant="outlined"
                                                            sx={{
                                                                color: theme => theme.palette.success.main,
                                                                borderColor: theme => theme.palette.success.main,
                                                                '&:hover': {
                                                                    backgroundColor: theme => theme.palette.success.dark,
                                                                    color: theme => theme.palette.common.white,
                                                                },
                                                            }}
                                                            size="small"
                                                            disabled
                                                        >
                                                            Check Prozess.. <CircularProgress size={13} sx={{ color: 'gray', marginLeft: 1 }}  />
                                                        </Button>
                                                    )
                                                )
                                            ) : (
                                                countpoolactive >= 8 &&
                                                <>
                                                    <Button
                                                        variant="outlined"
                                                        sx={{
                                                            color: 'red',
                                                            borderColor: 'red',
                                                            '&:hover': {
                                                                backgroundColor: 'red',
                                                                color: 'white',
                                                            },
                                                        }}
                                                        size="small"
                                                        disabled
                                                    >
                                                        No wins found
                                                    </Button>
                                                </>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ display: showSteps || countpoolactive >= 9 ? 'table-row' : 'none', borderBottom: '2px solid #ddd', backgroundColor: countpoolactive >= 9 ? '#d1e7dd' : '#fff3cd'}}>
                                        <TableCell sx={{ fontWeight: 'bold', padding: '0 0 0 2px', textAlign: isMobile ? 'center' : 'left', }}>
                                            <Tooltip title="Unlock the chance to win 3% of the total FlareDrop of the FTSOLottery by having 9 active pools." arrow
                                                     sx={{
                                                         display: isMobile ? 'none' : 'inline-block', // Hier wird das Tooltip auf Mobilgeräten ausgeblendet
                                                     }}
                                            >
                                                <InfoOutlined sx={{ height: '1.25rem', width: '1.25rem', color: '#2e7239' }} />
                                            </Tooltip>
                                            &nbsp;{step6}
                                        </TableCell>
                                        <TableCell sx={{ fontWeight: 'bold', textAlign: isMobile ? 'center' : 'left', }}>{airdropwinsarray[8]?.walletCount}</TableCell>
                                        <TableCell>

                                            {countpoolactive >= 9 ?
                                                <Typography
                                                    variant="body2"
                                                    component="span"
                                                    sx={{
                                                        backgroundColor: 'rgb(25, 135, 84, 1)',
                                                        color: 'white',
                                                        ml: 'auto',
                                                        p: 0.5,
                                                        borderRadius: '4px',
                                                    }}
                                                >
                                                    Active
                                                </Typography>
                                                :

                                                <Typography
                                                    variant="body2"
                                                    component="span"
                                                    sx={{
                                                        backgroundColor: 'rgb(220, 53, 69, 1)',
                                                        color: 'white',
                                                        ml: 'auto',
                                                        p: 0.5,
                                                        borderRadius: '4px',
                                                    }}
                                                >
                                                    Inactive
                                                </Typography>

                                            }

                                        </TableCell>
                                        <TableCell sx={{ textAlign: 'right' }}>
                                            {countpoolactive >= 9 && airdropwinsarray[8]?.volume > 0 ? (
                                                airdropwinsarray[8]?.is_clam_requested === 0 ? (
                                                    <Button
                                                        variant="outlined"
                                                        sx={{
                                                            color: theme => theme.palette.success.main,
                                                            borderColor: theme => theme.palette.success.main,
                                                            '&:hover': {
                                                                backgroundColor: theme => theme.palette.success.dark,
                                                                color: theme => theme.palette.common.white,
                                                            },
                                                        }}
                                                        size="small"
                                                        onClick={() => sendAirdropClaim(chain?.id, walletaddress, 8)}
                                                        disabled={loadingClaim8}
                                                    >
                                                        {loadingClaim8 ?
                                                            <>Claim im Prozess <CircularProgress size={13} sx={{ color: 'gray', marginLeft: 1 }}  /></>
                                                            :
                                                            <>Claim {`${Number(airdropwinsarray[8]?.volume).toFixed(3)} ${chain?.id === 14 ? 'WFLR' : 'WC2FLR'}`}</>
                                                        }
                                                    </Button>
                                                ) : (
                                                    airdropwinsarray[8]?.is_clam_requested_status === 1 ? (
                                                        <Button
                                                            variant="outlined"
                                                            sx={{
                                                                color: theme => theme.palette.success.main,
                                                                borderColor: theme => theme.palette.success.main,
                                                                '&:hover': {
                                                                    backgroundColor: theme => theme.palette.success.dark,
                                                                    color: theme => theme.palette.common.white,
                                                                },
                                                            }}
                                                            size="small"
                                                            disabled
                                                        >
                                                            Payout Prozess..  <CircularProgress size={13} sx={{ color: 'gray', marginLeft: 1 }}  />
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            variant="outlined"
                                                            sx={{
                                                                color: theme => theme.palette.success.main,
                                                                borderColor: theme => theme.palette.success.main,
                                                                '&:hover': {
                                                                    backgroundColor: theme => theme.palette.success.dark,
                                                                    color: theme => theme.palette.common.white,
                                                                },
                                                            }}
                                                            size="small"
                                                            disabled
                                                        >
                                                            Check Prozess.. <CircularProgress size={13} sx={{ color: 'gray', marginLeft: 1 }}  />
                                                        </Button>
                                                    )
                                                )
                                            ) : (
                                                countpoolactive >= 9 &&
                                                <>
                                                    <Button
                                                        variant="outlined"
                                                        sx={{
                                                            color: 'red',
                                                            borderColor: 'red',
                                                            '&:hover': {
                                                                backgroundColor: 'red',
                                                                color: 'white',
                                                            },
                                                        }}
                                                        size="small"
                                                        disabled
                                                    >
                                                        No wins found
                                                    </Button>
                                                </>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ display: showSteps || anglepoolactive ? 'table-row' : 'none', borderBottom: '2px solid #ddd', backgroundColor: anglepoolactive ? '#d1e7dd' : '#fff3cd'}}>
                                        <TableCell sx={{ fontWeight: 'bold', padding: '0 0 0 2px', textAlign: isMobile ? 'center' : 'left', }}>

                                            <Tooltip title="An active Angel has a chance to win 0.5% for third place, 1.5% for second place, and 3% for first place of the total FlareDrop in the FTSOLottery." arrow
                                                     sx={{
                                                         display: isMobile ? 'none' : 'inline-block', // Hier wird das Tooltip auf Mobilgeräten ausgeblendet
                                                     }}
                                            >
                                                <InfoOutlined sx={{ height: '1.25rem', width: '1.25rem', color: '#2e7239' }} />
                                            </Tooltip>
                                            &nbsp;Angel
                                        </TableCell>
                                        <TableCell sx={{ fontWeight: 'bold', textAlign: isMobile ? 'center' : 'left', }}>{airdropwinsarray[9]?.walletCount}</TableCell>
                                        <TableCell>

                                            {anglepoolactive ?
                                                <Typography
                                                    variant="body2"
                                                    component="span"
                                                    sx={{
                                                        backgroundColor: 'rgb(25, 135, 84, 1)',
                                                        color: 'white',
                                                        ml: 'auto',
                                                        p: 0.5,
                                                        borderRadius: '4px',
                                                    }}
                                                >
                                                    Active
                                                </Typography>
                                                :

                                                <Typography
                                                    variant="body2"
                                                    component="span"
                                                    sx={{
                                                        backgroundColor: 'rgb(220, 53, 69, 1)',
                                                        color: 'white',
                                                        ml: 'auto',
                                                        p: 0.5,
                                                        borderRadius: '4px',
                                                    }}
                                                >
                                                    Inactive
                                                </Typography>

                                            }

                                        </TableCell>
                                        <TableCell sx={{ textAlign: 'right' }}>
                                            {anglepoolactive && airdropwinsarray[9]?.volume > 0 ? (
                                                airdropwinsarray[9]?.is_clam_requested === 0 ? (
                                                    <Button
                                                        variant="outlined"
                                                        sx={{
                                                            color: theme => theme.palette.success.main,
                                                            borderColor: theme => theme.palette.success.main,
                                                            '&:hover': {
                                                                backgroundColor: theme => theme.palette.success.dark,
                                                                color: theme => theme.palette.common.white,
                                                            },
                                                        }}
                                                        size="small"
                                                        onClick={() => sendAirdropClaim(chain?.id, walletaddress, 9)}
                                                        disabled={loadingClaim9}
                                                    >
                                                        {loadingClaim9 ?
                                                            <>Claim im Prozess <CircularProgress size={13} sx={{ color: 'gray', marginLeft: 1 }}  /></>
                                                            :
                                                            <>Claim {`${Number(airdropwinsarray[9]?.volume).toFixed(3)} ${chain?.id === 14 ? 'WFLR' : 'WC2FLR'}`}</>
                                                        }
                                                    </Button>
                                                ) : (
                                                    airdropwinsarray[9]?.is_clam_requested_status === 1 ? (
                                                        <Button
                                                            variant="outlined"
                                                            sx={{
                                                                color: theme => theme.palette.success.main,
                                                                borderColor: theme => theme.palette.success.main,
                                                                '&:hover': {
                                                                    backgroundColor: theme => theme.palette.success.dark,
                                                                    color: theme => theme.palette.common.white,
                                                                },
                                                            }}
                                                            size="small"
                                                            disabled
                                                        >
                                                            Payout Prozess..  <CircularProgress size={13} sx={{ color: 'gray', marginLeft: 1 }}  />
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            variant="outlined"
                                                            sx={{
                                                                color: theme => theme.palette.success.main,
                                                                borderColor: theme => theme.palette.success.main,
                                                                '&:hover': {
                                                                    backgroundColor: theme => theme.palette.success.dark,
                                                                    color: theme => theme.palette.common.white,
                                                                },
                                                            }}
                                                            size="small"
                                                            disabled
                                                        >
                                                            Check Prozess.. <CircularProgress size={13} sx={{ color: 'gray', marginLeft: 1 }}  />
                                                        </Button>
                                                    )
                                                )
                                            ) : (
                                                anglepoolactive &&
                                                <>
                                                    <Button
                                                        variant="outlined"
                                                        sx={{
                                                            color: 'red',
                                                            borderColor: 'red',
                                                            '&:hover': {
                                                                backgroundColor: 'red',
                                                                color: 'white',
                                                            },
                                                        }}
                                                        size="small"
                                                        disabled
                                                    >
                                                        No wins found
                                                    </Button>
                                                </>
                                            )}
                                        </TableCell>
                                    </TableRow>

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Card>

            </>
        );
    };

    const sendPayment = async (pool: number, chainid: number | undefined, iswflr: boolean, spezialpool: boolean) => {
        try {
            setLoading(true);
            await sendTransactionToPool(pool, chainid, iswflr, spezialpool, address);
            setPaymentSuccessful(true);
        } catch (error) {
            console.log(error);
            setLoading(false);
        } finally {
            setLoading(false);
            setPaymentSuccessful(true);
        }
    }

    const sendPoolClaim = async (pool: number, chainid: number | undefined, waddress: `0x${string}` | undefined, type: number) => {
        try {
            if (type === 1) {
                setLoadingClaim1(true);
            } else if (type === 2) {
                setLoadingClaim2(true);
            }
            await sendClaimToApi(pool, chainid , waddress, type);
        } catch (error) {
            console.log(error);
            if (type === 1) {
                setLoadingClaim1(false);
            } else if (type === 2) {
                setLoadingClaim2(false);
            }
        } finally {
            if (type === 1) {
                setLoadingClaim1(false);
                setProzessClaim1(true);
            } else if (type === 2) {
                setLoadingClaim2(false);
                setProzessClaim2(true);
            }
        }
    }

    const sendPayout = async (pool: number, chainid: number | undefined, waddress: `0x${string}` | undefined) => {
        try {
            setLoadingPayout(true);
            await Swal.fire({
                title: 'Request payout?',
                text: 'If you cash out your deposit, you are no longer eligible to participate in the draw from that moment, please make sure to claim your winnings before paying out.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, Request payout!',
                cancelButtonText: 'No, cancel!',
                reverseButtons: true
            }).then(async (result) => { // <-- mark this anonymous function as async
                if (result.isConfirmed) {
                    await sendPoolPayoutToApi(pool, chainid, waddress);
                    setProzessPayout(true);
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    setLoadingPayout(false);
                    setProzessPayout(false);
                }
            })
        } catch (error) {
            console.log(error);
            setLoadingPayout(false);
        } finally {
            setLoadingPayout(false);
            setProzessPayout(true);
        }
    }

    const sendAirdropClaim = async (chainid: number | undefined, waddress: `0x${string}` | undefined, type: number) => {
        try {
            if (type === 3) {
                setLoadingClaim3(true);
            } else if (type === 4) {
                setLoadingClaim4(true);
            } else if (type === 5) {
                setLoadingClaim5(true);
            } else if (type === 6) {
                setLoadingClaim6(true);
            } else if (type === 7) {
                setLoadingClaim7(true);
            } else if (type === 8) {
                setLoadingClaim8(true);
            } else if (type === 9) {
                setLoadingClaim9(true);
            }
            await sendClaimToApi(0, chainid , waddress, type);
        } catch (error) {
            console.log(error);
            if (type === 3) {
                setLoadingClaim3(false);
            } else if (type === 4) {
                setLoadingClaim4(false);
            } else if (type === 5) {
                setLoadingClaim5(false);
            } else if (type === 6) {
                setLoadingClaim6(false);
            } else if (type === 7) {
                setLoadingClaim7(false);
            } else if (type === 8) {
                setLoadingClaim8(false);
            } else if (type === 9) {
                setLoadingClaim9(false);
            }
        } finally {
            if (type === 3) {
                setLoadingClaim3(false);
                setProzessClaim3(true);
            } else if (type === 4) {
                setLoadingClaim4(false);
                setProzessClaim4(true);
            } else if (type === 5) {
                setLoadingClaim5(false);
                setProzessClaim5(true);
            } else if (type === 6) {
                setLoadingClaim6(false);
                setProzessClaim6(true);
            } else if (type === 7) {
                setLoadingClaim7(false);
                setProzessClaim7(true);
            } else if (type === 8) {
                setLoadingClaim8(false);
                setProzessClaim8(true);
            } else if (type === 9) {
                setLoadingClaim9(false);
                setProzessClaim9(true);
            }
        }
    }

    const handleSendButtonSubmenuClose = () => {
        setAnchorEl(null);
    };

    const handleSendButtonSubmenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleSendButtonSubmenuItemClick = (value: boolean) => {
        setIsWFLR(value);
        setAnchorEl(null);
    };

    const handleSpezialSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSpezialSelected(event.target.checked);
    };

    const ActivateAnglePool = async (pool: number, chainid: number | undefined, waddress: `0x${string}` | undefined) => {
        try {
            setLoadingActivateAnglePool(true);
            await Swal.fire({
                title: 'Angel Pool Activate?',
                text: 'By activating the Angel Pool, you are agreeing to hold your stake until January 30th, 2026. In addition, you will have 3 more opportunities each month to win from the total FlareDrop of the FTSO Lottery. For more information, please refer to the rules or information provided in the pool description',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, Angel Pool Activate!',
                cancelButtonText: 'No, cancel!',
                reverseButtons: true
            }).then(async (result) => { // <-- mark this anonymous function as async
                if (result.isConfirmed) {
                    const output = await sendAnglePoolActivateToApi(pool, chainid, waddress);
                    if(output.status === true) {
                        window.location.reload();
                    }
                    if(output.status === false) {
                        console.log('An error has occurred, please try again later');
                    }
                    //console.log(output.status);
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    setSpezialSelected(false)
                }
            })
        } catch (error) {
            console.log(error);
            setLoadingActivateAnglePool(false);
        } finally {
            setLoadingActivateAnglePool(false);
        }
    }

    const switchToFlr = async () => {
        try {
            await switchNetwork({
                chainId: 14,
            });
        } catch (error) {
            console.log(error);
        }
    };

    const DisconectWallet = async () => {
        try {
            await disconnect();
        } catch (error) {
            console.log(error);
        }
    };


    return (
        <div>
            {chain && chain?.unsupported !== true && isPoolLoading ? (
                <>
                <Box sx={{ px: 4, py: 12 }}>
                    <Loading />
                </Box>
                </>
            ) : (
                <ClientOnly>
                    {!isConnected
                        ?
                        <HomeNotConected/>
                        :
                        <>

                            {chain && chain?.unsupported === true ?
                                <>
                                    <Container maxWidth="xl">
                                        <Box mt={8} p={4} bgcolor="#fde8e8" borderRadius="md">
                                            <Box display="flex">
                                                <Box flexShrink={0}>
                                                    <ReportGmailerrorredOutlined sx={{ height: '3rem', width: '3rem', color: '#ef4444' }} />
                                                </Box>
                                                <Box ml={2}>
                                                    <Typography variant="h5" fontWeight="bold">They are connected to the, {chain && <span>{chain.name}</span>} we don&apos;t support the chain.</Typography>
                                                    <Typography variant="body1">To use the FTSO Lottery, you need to be on the Flare Network. Please switch the chain to be able to utilize the FTSO Lottery.</Typography>
                                                    <Typography variant="h5" fontWeight="bold" mt={4}>We support:</Typography>
                                                    <ul style={{ listStyleType: 'disc', listStylePosition: 'inside', marginTop: '8px' }}>
                                                        <li>Lottery/Pools -&gt; Flare Network</li>
                                                        <li>Wrap/Unwrap Coins -&gt; Flare Network, Songbird</li>
                                                    </ul>
                                                    <Box mt={4}>
                                                        <Button variant="contained" sx={{ backgroundColor: '#6366f1', color: '#fff', marginRight: '8px' }} onClick={switchToFlr}>
                                                            Switch to Flare Network
                                                        </Button>
                                                        <Button variant="contained" sx={{ backgroundColor: '#dc2626', color: '#fff' }} onClick={DisconectWallet}>
                                                            Disconnect Wallet
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Container>
                                </>
                                :
                                <>
                                    {chain && chain?.unsupported !== true && chain?.id !== 14 && chain?.id !== 19 && chain?.id !== 114 ?
                                        <>
                                            <Container maxWidth="xl">
                                                <Box mt={8} p={4} bgcolor="#fde8e8" borderRadius="md">
                                                    <Box display="flex">
                                                        <Box flexShrink={0}>
                                                            <ReportGmailerrorredOutlined sx={{ height: '3rem', width: '3rem', color: '#ef4444' }} />
                                                        </Box>
                                                        <Box ml={2}>
                                                            <Typography variant="h5" fontWeight="bold">They are connected to the, {chain && <span>{chain.name}</span>} we don&apos;t support the chain.</Typography>
                                                            <Typography variant="body1">To use the FTSO Lottery, you need to be on the Flare Network. Please switch the chain to be able to utilize the FTSO Lottery.</Typography>
                                                            <Typography variant="h5" fontWeight="bold" mt={4}>We support:</Typography>
                                                            <ul style={{ listStyleType: 'disc', listStylePosition: 'inside', marginTop: '8px' }}>
                                                                <li>Lottery/Pools -&gt; Flare Network</li>
                                                                <li>Wrap/Unwrap Coins -&gt; Flare Network, Songbird</li>
                                                            </ul>
                                                            <Box mt={4}>
                                                                <Button variant="contained" sx={{ backgroundColor: '#6366f1', color: '#fff', marginRight: '8px' }} onClick={switchToFlr}>
                                                                    Switch to Flare Network
                                                                </Button>
                                                                <Button variant="contained" sx={{ backgroundColor: '#dc2626', color: '#fff' }} onClick={DisconectWallet}>
                                                                    Disconnect Wallet
                                                                </Button>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Container>
                                        </>
                                        :
                                        <>
                                        {chain && chain?.unsupported !== true && chain?.id === 19 ?
                                            <Container maxWidth="xl">
                                                <Box mt={8} p={4} bgcolor="#fde8e8" borderRadius="md">
                                                    <Box display="flex">
                                                        <Box flexShrink={0}>
                                                            <ReportGmailerrorredOutlined sx={{ height: '3rem', width: '3rem', color: '#ef4444' }} />
                                                        </Box>
                                                        <Box ml={2}>
                                                            <Typography variant="h5" fontWeight="bold">They are connected to the, {chain && <span>{chain.name}</span>} we don&apos;t support the chain.</Typography>
                                                            <Typography variant="body1">To use the FTSO Lottery, you need to be on the Flare Network. Please switch the chain to be able to utilize the FTSO Lottery.</Typography>
                                                            <Typography variant="h5" fontWeight="bold" mt={4}>We support:</Typography>
                                                            <ul style={{ listStyleType: 'disc', listStylePosition: 'inside', marginTop: '8px' }}>
                                                                <li>Lottery/Pools -&gt; Flare Network</li>
                                                                <li>Wrap/Unwrap Coins -&gt; Flare Network, Songbird</li>
                                                            </ul>
                                                            <Box mt={4}>
                                                                <Button variant="contained" sx={{ backgroundColor: '#6366f1', color: '#fff', marginRight: '8px' }} onClick={switchToFlr}>
                                                                    Switch to Flare Network
                                                                </Button>
                                                                <Button variant="contained" sx={{ backgroundColor: '#dc2626', color: '#fff' }} onClick={DisconectWallet}>
                                                                    Disconnect Wallet
                                                                </Button>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Container>
                                        :
                                        <>
                                            <Container maxWidth="xl" sx={{ textAlign: 'center', py: '8' }}>
                                                <Box
                                                    display="flex"
                                                    py={2}
                                                >
                                                    <Typography
                                                        variant="body2"
                                                        component="span"
                                                        sx={{
                                                            backgroundColor: 'rgb(180, 83, 9, 1)',
                                                            color: 'white',
                                                            ml: 'auto',
                                                            p: 0.3,
                                                            borderRadius: '4px',
                                                            marginRight: 1,
                                                        }}
                                                    >
                                                        <Cloud fontSize="small" /> Total FTSO Lottery Volume: {gesvolumeall} WFLR
                                                    </Typography>
                                                    <Link href="/contact" passHref>
                                                        <Typography
                                                            variant="body2"
                                                            component="a"
                                                            sx={{
                                                                display: 'inline-block',
                                                                backgroundColor: 'rgb(180, 83, 9, 1)',
                                                                color: 'white',
                                                                ml: 'auto',
                                                                p: 0.3,
                                                                borderRadius: '4px',
                                                                textDecoration: 'none', // Optional: Remove default underline for links
                                                                '&:hover': {
                                                                    backgroundColor: 'rgb(150, 70, 5, 1)', // Optional: Change color on hover
                                                                },
                                                            }}
                                                        >
                                                            <LiveHelp fontSize="small" />
                                                            {' '}
                                                            Custom Support
                                                        </Typography>
                                                    </Link>
                                                </Box>

                                                <Box >
                                                    <Box sx={{ maxWidth: "prose", lg: { maxWidth: "none" } }}>

                                                    {chain?.id === 14 ?
                                                            <AirdropRewards key={chain?.id} countpoolactive={CountPoolActive} anglepoolactive={AngelPoolActive} />
                                                            :
                                                            <></>
                                                        }

                                                        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '50vh' }}>
                                                            <Box sx={{ py: 2, my: 2, textAlign: 'center', flexGrow: 1 }}>
                                                                <Grid container spacing={2}>
                                                                    {Object.entries(poolarray).map(([key, value]: [string, any], index: number) => (
                                                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={4} key={index}>
                                                                            {value.activ === true ?
                                                                                <>
                                                                                    <Card
                                                                                        variant="outlined"
                                                                                        sx={{
                                                                                            marginBottom: '1rem',
                                                                                            borderRadius: '0.375rem',
                                                                                            boxShadow: value.checkstatus === 1 ? '0px 4px 8px rgba(0, 128, 0, 0.1)' : '0px 4px 8px rgba(255, 193, 7, 0.1)',
                                                                                        }}
                                                                                    >
                                                                                        <CardHeader
                                                                                            sx={{
                                                                                                backgroundColor: value.checkstatus === 1 ? 'rgb(25, 135, 84, 1)' : '#ffc107',
                                                                                                color: 'white',
                                                                                                padding: '8px',
                                                                                                display: 'flex',
                                                                                                justifyContent: 'space-between',
                                                                                                alignItems: 'center',
                                                                                                borderTopLeftRadius: '8px',
                                                                                                borderTopRightRadius: '8px',

                                                                                            }}
                                                                                            title={
                                                                                                <Typography variant="h6" sx={{ marginY: 0, fontWeight: 'normal', textAlign: 'left', paddingLeft: '10px' }}>
                                                                                                    {chain?.id === 14 && key === '10000' ? (
                                                                                                        <>Pool {key} / Angel</>
                                                                                                    ) : (
                                                                                                        <>Pool {key} </>
                                                                                                    )}
                                                                                                </Typography>
                                                                                            }
                                                                                            action={
                                                                                                    <Button
                                                                                                        sx={{
                                                                                                            color: 'white',
                                                                                                            float: 'right',
                                                                                                            '&:hover': {
                                                                                                                color: 'rgba(213,199,199,0.8)',
                                                                                                            },
                                                                                                        }}
                                                                                                        type="button"
                                                                                                        onClick={() => handlePoolInfoClick(key)}
                                                                                                    >
                                                                                                        <InfoOutlined sx={{ height: '2rem', width: '2rem' }} />
                                                                                                    </Button>

                                                                                            }
                                                                                            subheader={
                                                                                                <>
                                                                                                    {modalPoolInfoShow && (
                                                                                                        <PoolInfoModal
                                                                                                            show={modalPoolInfoShow}
                                                                                                            onHide={handlePoolInfoModalClose}
                                                                                                            pool={selectedPoolInfo || ''}
                                                                                                        />
                                                                                                    )}
                                                                                                </>
                                                                                            }
                                                                                        />



                                                                                        <CardContent>


                                                                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                                                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                                                                                        Volume
                                                                                                        </Typography>
                                                                                                    </Box>
                                                                                                    <Box sx={{ float: 'right', display: 'flex', alignItems: 'center' }}>
                                                                                                        {chain?.id === 14 ? <>{value.ges_pool_volume}</> : <>{value.ges_pool_volume}</>}
                                                                                                    </Box>
                                                                                                </Box>

                                                                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                                                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                                                                                        Wallets
                                                                                                        </Typography>
                                                                                                    </Box>
                                                                                                    <Box sx={{ float: 'right', display: 'flex', alignItems: 'center' }}>
                                                                                                        {chain?.id === 14 ? <>{value.holder}</> : <>{value.holder}</>}
                                                                                                    </Box>
                                                                                                </Box>

                                                                                                {chain?.id === 14 && key === '10000' ?
                                                                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                                                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                                                                                                Angels
                                                                                                            </Typography>
                                                                                                        </Box>
                                                                                                        <Box sx={{ float: 'right', display: 'flex', alignItems: 'center' }}>
                                                                                                            {value.spezial_pool_count}/500
                                                                                                        </Box>
                                                                                                    </Box>
                                                                                                    :
                                                                                                    <></>
                                                                                                }

                                                                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                                                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                                                                                        Lottery Epoche
                                                                                                        </Typography>
                                                                                                    </Box>
                                                                                                    <Box sx={{ float: 'right', display: 'flex', alignItems: 'center' }}>
                                                                                                        <Countdown date={new Date(value.end_delegate_epoche * 1000)} renderer={countdownRenderer} />
                                                                                                    </Box>
                                                                                                </Box>

                                                                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                                                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                                                                                            Lottery Month
                                                                                                        </Typography>
                                                                                                    </Box>
                                                                                                    <Box sx={{ float: 'right', display: 'flex', alignItems: 'center' }}>
                                                                                                        <Countdown date={new Date(value.end_airdrop_epoche * 1000)} renderer={countdownRenderer} />
                                                                                                    </Box>
                                                                                                </Box>

                                                                                                {chain?.id === 14 && key === '10000' ?
                                                                                                    <>
                                                                                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                                                                                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                                                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>

                                                                                                                    Angel Pool Activate&nbsp;
                                                                                                                    <Tooltip title="Join the exclusive group of 500 members who can activate the angel pool. For more information, please refer to the Pool Info or the rules. Members of the Angel Pool remain active and Locked until January 30, 2026." arrow>
                                                                                                                        <InfoOutlined sx={{ height: '1rem', width: '1rem', color: '#2e7239' }} />
                                                                                                                    </Tooltip>
                                                                                                                </Typography>
                                                                                                            </Box>
                                                                                                            <Box sx={{ float: 'right', display: 'flex', alignItems: 'center' }}>

                                                                                                                {value.spezial_pool_activ === true ?

                                                                                                                    <Switch
                                                                                                                        onChange={handleSpezialSwitchChange}
                                                                                                                        size="small"
                                                                                                                        color="warning"
                                                                                                                        defaultChecked
                                                                                                                        disabled
                                                                                                                    />
                                                                                                                    :
                                                                                                                    <Switch
                                                                                                                        onChange={handleSpezialSwitchChange}
                                                                                                                        size="small"
                                                                                                                        color="warning"
                                                                                                                    />
                                                                                                                }
                                                                                                            </Box>
                                                                                                        </Box>
                                                                                                    </>
                                                                                                    :
                                                                                                    <></>
                                                                                                }



                                                                                            <div className="mt-6">
                                                                                                {value.off_time_avtiv == true ?
                                                                                                    <>
                                                                                                        {value.checkstatus == 1 ?
                                                                                                            <>
                                                                                                                {key === '10000' ?
                                                                                                                    <>
                                                                                                                        {spezialselected  ?
                                                                                                                                <Button
                                                                                                                                    fullWidth
                                                                                                                                    variant="outlined"
                                                                                                                                    type="button"
                                                                                                                                    onClick={() => ActivateAnglePool(Number(key), chain?.id, walletaddress)}
                                                                                                                                    disabled={loadingActivateAnglePool}
                                                                                                                                    sx={{
                                                                                                                                        backgroundColor: 'transparent',
                                                                                                                                        borderColor: theme.palette.info.main,
                                                                                                                                        color: theme.palette.info.main,
                                                                                                                                        '&:hover': {
                                                                                                                                            backgroundColor: theme.palette.info.light,
                                                                                                                                            color: 'white',
                                                                                                                                        },
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    {loadingActivateAnglePool ? <>Activate in Prozess...</> : <>Activate Angel Pool</>}
                                                                                                                                </Button>
                                                                                                                            :
                                                                                                                            <>
                                                                                                                                {value.spezial_pool_activ === true ?
                                                                                                                                    <Button
                                                                                                                                        fullWidth
                                                                                                                                        variant="outlined"
                                                                                                                                        color="error"
                                                                                                                                        sx={{
                                                                                                                                            paddingLeft: 1,
                                                                                                                                            paddingRight: 1,
                                                                                                                                        }}
                                                                                                                                        disabled={true}
                                                                                                                                    >
                                                                                                                                        Payout Locked until January 30, 2026
                                                                                                                                    </Button>
                                                                                                                                    :
                                                                                                                                    <Button
                                                                                                                                        fullWidth
                                                                                                                                        variant="outlined"
                                                                                                                                        color="error"
                                                                                                                                        sx={{
                                                                                                                                            paddingLeft: 1,
                                                                                                                                            paddingRight: 1,
                                                                                                                                        }}
                                                                                                                                        disabled={true}
                                                                                                                                    >
                                                                                                                                        Payout Locked until:  {value.off_time_format}
                                                                                                                                    </Button>
                                                                                                                                }

                                                                                                                            </>
                                                                                                                        }

                                                                                                                    </>
                                                                                                                    :
                                                                                                                    <Button
                                                                                                                        fullWidth
                                                                                                                        variant="outlined"
                                                                                                                        color="error"
                                                                                                                        sx={{
                                                                                                                            paddingLeft: 1,
                                                                                                                            paddingRight: 1,
                                                                                                                        }}
                                                                                                                        disabled={true}
                                                                                                                    >
                                                                                                                        Payout Locked until:  {value.off_time_format}
                                                                                                                    </Button>
                                                                                                                }
                                                                                                            </>
                                                                                                            :
                                                                                                            <>
                                                                                                                <Button
                                                                                                                    fullWidth
                                                                                                                    variant="outlined"
                                                                                                                    color="error"
                                                                                                                    sx={{
                                                                                                                        paddingLeft: 1,
                                                                                                                        paddingRight: 1,
                                                                                                                    }}
                                                                                                                    disabled={true}
                                                                                                                >
                                                                                                                    Payment in check status
                                                                                                                </Button>
                                                                                                            </>
                                                                                                        }


                                                                                                    </>
                                                                                                    :
                                                                                                    <>
                                                                                                        {key === '10000' ?
                                                                                                            <>
                                                                                                                {spezialselected  ?
                                                                                                                    <Button
                                                                                                                        fullWidth
                                                                                                                        variant="outlined"
                                                                                                                        type="button"
                                                                                                                        onClick={() => ActivateAnglePool(Number(key), chain?.id, walletaddress)}
                                                                                                                        disabled={loadingActivateAnglePool}
                                                                                                                        sx={{
                                                                                                                            backgroundColor: 'transparent',
                                                                                                                            borderColor: theme.palette.info.main,
                                                                                                                            color: theme.palette.info.main,
                                                                                                                            '&:hover': {
                                                                                                                                backgroundColor: theme.palette.info.light,
                                                                                                                                color: 'white',
                                                                                                                            },
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        {loadingActivateAnglePool ? <>Activate in Prozess...</> : <>Activate Angel Pool</>}
                                                                                                                    </Button>
                                                                                                                    :
                                                                                                                    <>
                                                                                                                        {value.payment_requested === true ?
                                                                                                                            <Button
                                                                                                                                fullWidth
                                                                                                                                variant="outlined"
                                                                                                                                color="error"
                                                                                                                                sx={{
                                                                                                                                    paddingLeft: 1,
                                                                                                                                    paddingRight: 1,
                                                                                                                                }}
                                                                                                                                disabled={true}
                                                                                                                            >
                                                                                                                                payout in Prozess
                                                                                                                            </Button>
                                                                                                                            :
                                                                                                                            <Button
                                                                                                                                fullWidth
                                                                                                                                variant="outlined"
                                                                                                                                type="button"
                                                                                                                                onClick={() => sendPayout(Number(key), chain?.id, walletaddress)}
                                                                                                                                disabled={loadingPayout}
                                                                                                                                sx={{
                                                                                                                                    backgroundColor: 'transparent',
                                                                                                                                    borderColor: theme.palette.info.main,
                                                                                                                                    color: theme.palette.info.main,
                                                                                                                                    '&:hover': {
                                                                                                                                        backgroundColor: theme.palette.info.light,
                                                                                                                                        color: 'white',
                                                                                                                                    },
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                {loadingPayout ? <>Payout in Prozess...</> : <>Payout</>}
                                                                                                                            </Button>
                                                                                                                        }
                                                                                                                    </>
                                                                                                                }
                                                                                                            </>
                                                                                                            :
                                                                                                            <>
                                                                                                                {value.payment_requested === true ?
                                                                                                                    <Button
                                                                                                                        fullWidth
                                                                                                                        variant="outlined"
                                                                                                                        color="error"
                                                                                                                        sx={{
                                                                                                                            paddingLeft: 1,
                                                                                                                            paddingRight: 1,
                                                                                                                        }}
                                                                                                                        disabled={true}
                                                                                                                    >
                                                                                                                        payout in Prozess
                                                                                                                    </Button>
                                                                                                                    :
                                                                                                                    <>
                                                                                                                        <Button
                                                                                                                            fullWidth
                                                                                                                            variant="outlined"
                                                                                                                            type="button"
                                                                                                                            onClick={() => sendPayout(Number(key), chain?.id, walletaddress)}
                                                                                                                            disabled={loadingPayout}
                                                                                                                            sx={{
                                                                                                                                backgroundColor: 'transparent',
                                                                                                                                borderColor: theme.palette.info.main,
                                                                                                                                color: theme.palette.info.main,
                                                                                                                                '&:hover': {
                                                                                                                                    backgroundColor: theme.palette.info.light,
                                                                                                                                    color: 'white',
                                                                                                                                },
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            {loadingPayout ? <>Payout in Prozess...</> : <>Request Payout</>}
                                                                                                                        </Button>
                                                                                                                    </>
                                                                                                                }
                                                                                                            </>
                                                                                                        }
                                                                                                    </>
                                                                                                }
                                                                                            </div>

                                                                                        </CardContent>

                                                                                        <CardActions
                                                                                            sx={{
                                                                                                display: 'flex',
                                                                                                flexDirection: 'column',
                                                                                                backgroundColor: '#f5f5f5',
                                                                                            }}
                                                                                        >
                                                                                            <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" marginLeft="0.25rem" marginTop="0.25rem">
                                                                                                <Typography>Lottery Epoche</Typography>
                                                                                                <Box display="flex" alignItems="center" marginLeft="1rem">
                                                                                                    <Box marginLeft="1rem">
                                                                                                        <Box display="flex" alignItems="center">
                                                                                                            {Number(value.dest_claims) > 0 ?

                                                                                                                <Button
                                                                                                                    variant="outlined"
                                                                                                                    sx={{
                                                                                                                        color: theme => theme.palette.success.main,
                                                                                                                        borderColor: theme => theme.palette.success.main,
                                                                                                                        '&:hover': {
                                                                                                                            backgroundColor: theme => theme.palette.success.dark,
                                                                                                                            color: theme => theme.palette.common.white,
                                                                                                                        },
                                                                                                                    }}
                                                                                                                    size="small"
                                                                                                                    onClick={() => sendPoolClaim(Number(key), chain?.id, walletaddress, 1)}
                                                                                                                    disabled={loadingClaim1}
                                                                                                                >
                                                                                                                    {loadingClaim1 ?
                                                                                                                        <>Claim im Prozess <CircularProgress size={13} sx={{ color: 'gray', marginLeft: 1 }}  /></>
                                                                                                                        :
                                                                                                                        <>Claim {`${Number(value.dest_claims).toFixed(3)} ${chain?.id === 14 ? 'WFLR' : 'WC2FLR'}`}</>
                                                                                                                    }
                                                                                                                </Button>

                                                                                                                :
                                                                                                                <Button
                                                                                                                    variant="outlined"
                                                                                                                    sx={{
                                                                                                                        color: 'red',
                                                                                                                        borderColor: 'red',
                                                                                                                        '&:hover': {
                                                                                                                            backgroundColor: 'red',
                                                                                                                            color: 'white',
                                                                                                                        },
                                                                                                                    }}
                                                                                                                    size="small"
                                                                                                                    disabled
                                                                                                                >
                                                                                                                    {value.dest_claims_count > 0 ? 'Claim in process...' : 'No wins found'}
                                                                                                                </Button>
                                                                                                            }
                                                                                                        </Box>
                                                                                                    </Box>
                                                                                                </Box>
                                                                                            </Box>

                                                                                            <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" marginLeft="0.2rem" marginTop="0.25rem">
                                                                                                <Typography>Lottery Month</Typography>
                                                                                                <Box display="flex" alignItems="center" marginLeft="1rem">
                                                                                                    <Box marginLeft="1rem">
                                                                                                        <Box display="flex" alignItems="center">
                                                                                                            {Number(value.pool_airdrop_claims) > 0 ?
                                                                                                                <Button
                                                                                                                    variant="outlined"
                                                                                                                    sx={{
                                                                                                                        color: theme => theme.palette.success.main,
                                                                                                                        borderColor: theme => theme.palette.success.main,
                                                                                                                        '&:hover': {
                                                                                                                            backgroundColor: theme => theme.palette.success.dark,
                                                                                                                            color: theme => theme.palette.common.white,
                                                                                                                        },
                                                                                                                    }}
                                                                                                                    size="small"
                                                                                                                    onClick={() => sendPoolClaim(Number(key), chain?.id, walletaddress, 2)}
                                                                                                                    disabled={loadingClaim2}
                                                                                                                >
                                                                                                                    {loadingClaim2 ?
                                                                                                                        <>Claim im Prozess <CircularProgress size={13} sx={{ color: 'gray', marginLeft: 1 }}  /></>
                                                                                                                        :
                                                                                                                        <>Claim {`${Number(value.pool_airdrop_claims).toFixed(3)} ${chain?.id === 14 ? 'WFLR' : 'WC2FLR'}`}</>
                                                                                                                    }
                                                                                                                </Button>
                                                                                                                :
                                                                                                                <Button
                                                                                                                    variant="outlined"
                                                                                                                    sx={{
                                                                                                                        color: 'red',
                                                                                                                        borderColor: 'red',
                                                                                                                        '&:hover': {
                                                                                                                            backgroundColor: 'red',
                                                                                                                            color: 'white',
                                                                                                                        },
                                                                                                                    }}
                                                                                                                    size="small"
                                                                                                                    disabled
                                                                                                                >
                                                                                                                    {value.pool_airdrop_claims_count > 0 ? 'Claim in process...' : 'No wins found'}
                                                                                                                </Button>
                                                                                                            }
                                                                                                        </Box>
                                                                                                    </Box>
                                                                                                </Box>
                                                                                            </Box>
                                                                                        </CardActions>
                                                                                    </Card>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <Card variant="outlined" sx={{ borderRadius: '0.375rem', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                                                                        <CardHeader
                                                                                            sx={{
                                                                                                backgroundColor: 'rgb(108, 117, 125, 1)',
                                                                                                color: 'white',
                                                                                                padding: '8px',
                                                                                                display: 'flex',
                                                                                                justifyContent: 'space-between',
                                                                                                alignItems: 'center',
                                                                                                borderTopLeftRadius: '8px',
                                                                                                borderTopRightRadius: '8px',

                                                                                            }}
                                                                                            title={
                                                                                                <Typography variant="h6" sx={{ marginY: 0, fontWeight: 'normal', textAlign: 'left', paddingLeft: '10px' }}>
                                                                                                    {chain?.id === 14 && key === '10000' ? (
                                                                                                        <>Pool {key} / Angel</>
                                                                                                    ) : (
                                                                                                        <>Pool {key} </>
                                                                                                    )}
                                                                                                </Typography>
                                                                                            }
                                                                                            action={
                                                                                                    <Button
                                                                                                        sx={{
                                                                                                            color: 'white',
                                                                                                            float: 'right',
                                                                                                            '&:hover': {
                                                                                                                color: 'rgba(213,199,199,0.8)',
                                                                                                            },
                                                                                                        }}
                                                                                                        type="button"
                                                                                                        onClick={() => handlePoolInfoClick(key)}
                                                                                                    >
                                                                                                        <InfoOutlined sx={{ height: '2rem', width: '2rem' }} />
                                                                                                    </Button>

                                                                                            }
                                                                                            subheader={
                                                                                            <>
                                                                                                {modalPoolInfoShow && (
                                                                                                    <PoolInfoModal
                                                                                                        show={modalPoolInfoShow}
                                                                                                        onHide={handlePoolInfoModalClose}
                                                                                                        pool={selectedPoolInfo || ''}
                                                                                                    />
                                                                                                )}
                                                                                            </>
                                                                                            }
                                                                                        />

                                                                                        <CardContent>


                                                                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                                                                                        Volume
                                                                                                    </Typography>
                                                                                                </Box>
                                                                                                <Box sx={{ float: 'right', display: 'flex', alignItems: 'center' }}>
                                                                                                    {chain?.id === 14 ? <>{value.ges_pool_volume}</> : <>{value.ges_pool_volume}</>}
                                                                                                </Box>
                                                                                            </Box>

                                                                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                                                                                        Wallets
                                                                                                    </Typography>
                                                                                                </Box>
                                                                                                <Box sx={{ float: 'right', display: 'flex', alignItems: 'center' }}>
                                                                                                    {chain?.id === 14 ? <>{value.holder}</> : <>{value.holder}</>}
                                                                                                </Box>
                                                                                            </Box>

                                                                                            {chain?.id === 14 && key === '10000' ?
                                                                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                                                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                                                                                            Angels
                                                                                                        </Typography>
                                                                                                    </Box>
                                                                                                    <Box sx={{ float: 'right', display: 'flex', alignItems: 'center' }}>
                                                                                                        {value.spezial_pool_count}/500
                                                                                                    </Box>
                                                                                                </Box>
                                                                                                :
                                                                                                <></>
                                                                                            }

                                                                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                                                                                        Lottery Epoche
                                                                                                    </Typography>
                                                                                                </Box>
                                                                                                <Box sx={{ float: 'right', display: 'flex', alignItems: 'center' }}>
                                                                                                    <Countdown date={new Date(value.end_delegate_epoche * 1000)} renderer={countdownRenderer} />
                                                                                                </Box>
                                                                                            </Box>


                                                                                            {chain?.id === 14 ?
                                                                                                <>
                                                                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                                                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                                                                                                Lottery Month
                                                                                                            </Typography>
                                                                                                        </Box>
                                                                                                        <Box sx={{ float: 'right', display: 'flex', alignItems: 'center' }}>
                                                                                                            <Countdown date={new Date(value.end_airdrop_epoche * 1000)} renderer={countdownRenderer} />
                                                                                                        </Box>
                                                                                                    </Box>
                                                                                                </>
                                                                                                :
                                                                                                <></>
                                                                                            }

                                                                                            {chain?.id === 14 && key === '10000' ?
                                                                                                <>
                                                                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                                                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                                                                                                Angel Pool Activate&nbsp;
                                                                                                                <Tooltip title="Join the exclusive group of 500 members who can activate the angel pool. For more information, please refer to the Pool Info or the rules. Members of the Angel Pool remain active and Locked until January 30, 2026." arrow>
                                                                                                                    <InfoOutlined sx={{ height: '1rem', width: '1rem', color: '#2e7239' }} />
                                                                                                                </Tooltip>
                                                                                                            </Typography>
                                                                                                        </Box>
                                                                                                        <Box sx={{ float: 'right', display: 'flex', alignItems: 'center' }}>

                                                                                                            {value.spezial_pool_activ === true ?
                                                                                                                <Switch
                                                                                                                    onChange={handleSpezialSwitchChange}
                                                                                                                    color="warning"
                                                                                                                    size="small"
                                                                                                                    defaultChecked
                                                                                                                    disabled
                                                                                                                />
                                                                                                                :
                                                                                                                <Switch
                                                                                                                    onChange={handleSpezialSwitchChange}
                                                                                                                    size="small"
                                                                                                                    color="warning"
                                                                                                                />
                                                                                                            }
                                                                                                        </Box>
                                                                                                    </Box>
                                                                                                </>
                                                                                                :
                                                                                                <></>
                                                                                            }

                                                                                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                                                                                                <Box sx={{ width: '100%', paddingTop: '10px' }}>

                                                                                                    <ButtonGroup fullWidth variant="contained" color="warning">
                                                                                                        <Button
                                                                                                            color="warning"
                                                                                                            disabled={loading}
                                                                                                            onClick={async () => {
                                                                                                                try {
                                                                                                                    if (spezialselected) {
                                                                                                                        await sendPayment(Number(key), chain?.id, isWFLR, spezialselected);
                                                                                                                    } else {
                                                                                                                        await sendPayment(Number(key), chain?.id, isWFLR, spezialselected);
                                                                                                                    }
                                                                                                                } catch (error) {
                                                                                                                    console.error('Fehler beim Senden der Zahlung:', error);
                                                                                                                }
                                                                                                            }}
                                                                                                            startIcon={loading && <>{/* Icon hinzufügen, wenn erforderlich */}</>}
                                                                                                        >
                                                                                                            {loading ? 'im Prozess...' :
                                                                                                                <>
                                                                                                                    Stake {key}
                                                                                                                </>
                                                                                                            }
                                                                                                        </Button>
                                                                                                        <Button
                                                                                                            sx={{ width: '100px' }}
                                                                                                            color="warning"
                                                                                                            aria-controls={anchorEl ? 'split-button-menu' : undefined}
                                                                                                            aria-expanded={Boolean(anchorEl)}
                                                                                                            aria-label="select merge strategy"
                                                                                                            aria-haspopup="menu"
                                                                                                            onClick={handleSendButtonSubmenuClick}
                                                                                                            disabled={loading}
                                                                                                            endIcon={
                                                                                                                <Box>
                                                                                                                     <ArrowDropDown />
                                                                                                                </Box>
                                                                                                            }
                                                                                                        >
                                                                                                            {chain?.id === 14 ? (
                                                                                                                <>
                                                                                                                    {!isWFLR ? 'FLR' : 'WFLR'}
                                                                                                                </>
                                                                                                            ) : chain?.id === 19 ? (
                                                                                                                <>
                                                                                                                    {!isWFLR ? 'SGB' : 'WSGB'}
                                                                                                                </>
                                                                                                            ) : chain?.id === 16 ? (
                                                                                                                <>
                                                                                                                    {!isWFLR ? 'CFLR' : 'WCFLR'}
                                                                                                                </>
                                                                                                            ) : chain?.id === 114 ? (
                                                                                                                <>
                                                                                                                    {!isWFLR ? 'C2FLR' : 'WC2FLR'}
                                                                                                                </>
                                                                                                            ) : (
                                                                                                                ''
                                                                                                            )}
                                                                                                        </Button>
                                                                                                        <Menu
                                                                                                            id="split-button-menu"
                                                                                                            anchorEl={anchorEl}
                                                                                                            open={Boolean(anchorEl)}
                                                                                                            onClose={handleSendButtonSubmenuClose}
                                                                                                            anchorOrigin={{
                                                                                                                vertical: 'bottom',
                                                                                                                horizontal: 'right',
                                                                                                            }}
                                                                                                            transformOrigin={{
                                                                                                                vertical: 'top',
                                                                                                                horizontal: 'right',
                                                                                                            }}
                                                                                                        >
                                                                                                            <MenuItem onClick={() => handleSendButtonSubmenuItemClick(false)}>Send in FLR</MenuItem>
                                                                                                            <MenuItem onClick={() => handleSendButtonSubmenuItemClick(true)}>Send in WFLR</MenuItem>
                                                                                                        </Menu>
                                                                                                    </ButtonGroup>

                                                                                                </Box>

                                                                                            </Box>



                                                                                        </CardContent>

                                                                                    </Card>
                                                                                </>
                                                                            }
                                                                        </Grid>
                                                                    ))}

                                                                </Grid>
                                                            </Box>
                                                        </Box>

                                                    </Box>

                                                </Box>
                                            </Container>
                                        </>
                                        }




                                        </>
                                    }

                                </>
                            }



                        </>
                    }
                </ClientOnly>
            )}

        </div>
    );
};
